import { FC, MouseEventHandler, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonProps,
  Grid,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';

import {
  ACCESS_PERMISSIONS,
  ActionDisableGuard,
  HasPermission,
} from '@/features/common/account';
import { useOnboardingUseCase } from '@/features/common/onboarding';

import {
  ChromeIcon,
  CopyIcon,
  FeatureIcon,
  FlashOutlinedIcon,
  GroupManagementIcon,
  HelpAndSupportIcon,
  LaunchIcon,
  LogOutIcon,
  SettingsIcon,
  TagManagementIcon,
} from '@/components/Icons';

import { useClipboardWithToast } from '@/hooks';

import { getAppVersion } from '@/utils/getAppVersion';

import { useUserMenuHandlers } from '../../hooks';
import { UserData } from '../../types';

import { UserMenuBillingSection } from './UserMenuBillingSection';

import styles from './styles.module.scss';

type UserMenuProps = UserData & {
  initials: string;
  navigateToSettings(): void;
  onLogout: () => void;
  navigateToTeamManagement(): void;
  navigateToSubscription(): void;
  navigateToTagManagement(): void;
  navigateToHelpCenter(): void;
  onGetFreeCreditsClick: () => void;
  onReferFriendClick: () => void;
  handleInviteTeamMember: () => void;
};

const MenuButton: FC<PropsWithChildren<ButtonProps>> = ({ children, ...props }) => {
  const { onClick, ...otherProps } = props;
  const { onClose } = useUserMenuHandlers();

  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) {
      onClick(e);
      onClose();
    }
  };

  return (
    <Button
      variant="text"
      size="small"
      className={styles.textButton}
      style={{ padding: 0 }}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const UserMenuPopover: FC<UserMenuProps> = ({
  fullName,
  initials,
  email,
  planType,
  userLogo,
  creditsUsed,
  creditsLimit,
  role,
  navigateToSettings,
  navigateToTeamManagement,
  navigateToSubscription,
  navigateToTagManagement,
  navigateToHelpCenter,
  // onReferFriendClick,
  onLogout,
  handleInviteTeamMember,
}) => {
  const { t } = useTranslation();

  const copyToClipboard = useClipboardWithToast();
  const onboardingUseCase = useOnboardingUseCase();

  return (
    <Stack p="10px" minWidth="280px" gap={1}>
      <Grid>
        <Box pl="3px" pt="3px" width="46px" height="46px">
          <Avatar alt={fullName} src={userLogo} className={styles.avatar}>
            {initials}
          </Avatar>
        </Box>

        <Box mr={1.25} />
        <Stack justifyContent="center">
          <Typography variant="h3">{fullName}</Typography>
          <Typography variant="subtitle2" color="info">
            {email}
          </Typography>
        </Stack>
      </Grid>

      <Box className={styles.divider} />

      <UserMenuBillingSection
        role={role}
        planType={planType}
        creditsUsed={creditsUsed}
        creditsLimit={creditsLimit}
      />

      <Stack alignItems="flex-start">
        <MenuButton
          onClick={navigateToSettings}
          startIcon={<SettingsIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.account')}</Typography>
        </MenuButton>

        <MenuButton
          onClick={navigateToTeamManagement}
          startIcon={<GroupManagementIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.teamManagement')}</Typography>
        </MenuButton>

        <HasPermission permission={ACCESS_PERMISSIONS.CAN_ACCESS_BILLING}>
          <MenuButton
            onClick={navigateToSubscription}
            startIcon={<FlashOutlinedIcon color="info" />}
          >
            <Typography variant="subtitle2">{t('header.subscription')}</Typography>
          </MenuButton>
        </HasPermission>

        <MenuButton
          onClick={navigateToTagManagement}
          startIcon={<TagManagementIcon color="info" />}
        >
          <Typography variant="subtitle2">{t('header.tagManagement')}</Typography>
        </MenuButton>
      </Stack>

      <Box className={styles.divider} />

      <HasPermission permission={ACCESS_PERMISSIONS.CAN_MANAGE_TEAM_MEMBERS}>
        <Stack alignItems="flex-start">
          <ActionDisableGuard>
            <MenuButton
              onClick={handleInviteTeamMember}
              startIcon={<GroupManagementIcon color="info" />}
              size="small"
            >
              <Typography variant="subtitle2">{t('header.inviteTeamMember')}</Typography>
            </MenuButton>
          </ActionDisableGuard>
        </Stack>
        <Box className={styles.divider} />
      </HasPermission>

      <Stack alignItems="flex-start">
        {/* {planType === PlanType.Free && (
          <MenuButton onClick={onReferFriendClick} startIcon={<ReferFriendIcon />}>
            <Typography variant="subtitle2">{t('header.referFriend')}</Typography>
          </MenuButton>
        )} */}
        <MenuButton startIcon={<FeatureIcon color="info" />}>
          <Typography variant="subtitle2">
            <Link
              href={import.meta.env.REACT_APP_SUGGEST_FEATURE_URL}
              target="_blank"
              className={styles.externalLink}
            >
              {t('header.suggestFeature')}
            </Link>
          </Typography>
        </MenuButton>

        <Grid justifyContent="space-between" alignItems="center">
          <MenuButton startIcon={<ChromeIcon color="info" />}>
            <Typography variant="subtitle2">
              <Link
                href={import.meta.env.REACT_APP_EXTENSION_URL}
                target="_blank"
                className={styles.extensionLink}
                onClick={(): void => {
                  onboardingUseCase.completeClickDownloadExtensionStep();
                }}
              >
                {t('header.addExtension')}
              </Link>
            </Typography>
          </MenuButton>
          <IconButton
            className={styles.copyIcon}
            size="small"
            focusRipple
            onClick={(): void => {
              copyToClipboard(import.meta.env.REACT_APP_EXTENSION_URL);
              onboardingUseCase.completeClickDownloadExtensionStep();
            }}
          >
            <CopyIcon color="info" />
          </IconButton>
        </Grid>
        <Grid justifyContent="space-between" alignItems="center">
          <MenuButton
            startIcon={<HelpAndSupportIcon color="info" />}
            onClick={navigateToHelpCenter}
          >
            <Typography variant="subtitle2">{t('header.helpAndSupport')}</Typography>
          </MenuButton>
          <LaunchIcon color="info" className={styles.launchIcon} />
        </Grid>

        <MenuButton startIcon={<LogOutIcon color="info" />} onClick={onLogout}>
          <Typography variant="subtitle2">{t('header.logout')}</Typography>
        </MenuButton>
      </Stack>

      {/* {hasUserReceivedFreeCredits || planType !== PlanType.Free ? null : (
        <>
          <Box className={styles.divider} />

          <Stack alignItems="flex-start">
            <ActionDisableGuard>
              <Button
                variant="text"
                size={BaseSizeEnum.MEDIUM}
                className={styles.lastTextButton}
                startIcon={<GetFreeCredits />}
                onClick={onGetFreeCreditsClick}
              >
                <Typography variant="subtitle2">{t('header.getFreeCredits')}</Typography>
              </Button>
            </ActionDisableGuard>
          </Stack>
        </>
      )} */}

      <Box className={styles.divider} />

      <Stack alignItems="flex-end">
        <Typography variant="subtitle3" color="info">
          {t('header.version', {
            version: getAppVersion(),
          })}
        </Typography>
      </Stack>
    </Stack>
  );
};
