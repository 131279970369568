import { AxiosError, type AxiosRequestConfig, type AxiosResponse } from 'axios';
import Axios, { type AxiosObservable } from 'axios-observable';
import { inject, injectable } from 'inversify';
import { first, fromEvent, Observable, switchMap } from 'rxjs';

import { AUTH_TYPES, GLOBAL_ERROR_TYPES, LEADS_TRACKING_TYPES } from '@/ioc/types';

import { IAuthRepository } from '@/features/common/auth';
// import { ReferrerTokenKey } from '@/features/referral';
import { IGlobalErrorRepository } from '@/features/system/globalError';
import type { ILeadsTrackingRepository } from '@/features/system/leadsTracking';

import { getApiUrl } from '@/utils/getApiUrl';
import { getAppVersion } from '@/utils/getAppVersion';

import {
  ConnectionError,
  InternalServerError,
  NetworkError,
  UnauthorizedError,
} from './errors';

export type HttpClientResponseObservable<T> = AxiosObservable<T>;

export interface IHttpClient {
  get: <T>(url: string, config?: AxiosRequestConfig) => HttpClientResponseObservable<T>;
  post: <T>(url: string, data) => HttpClientResponseObservable<T>;
  put: <T>(url: string, data) => HttpClientResponseObservable<T>;
  patch: <T>(url: string, data) => HttpClientResponseObservable<T>;
  delete: <T>(url: string, data) => HttpClientResponseObservable<T>;
  request: <T>(config: AxiosRequestConfig<T>) => HttpClientResponseObservable<T>;
}

@injectable()
export default class HttpClient implements IHttpClient {
  @inject(GLOBAL_ERROR_TYPES.GlobalErrorRepository)
  private globalErrorRepository: IGlobalErrorRepository;

  private leadsTrackingRepository: ILeadsTrackingRepository;

  private client: Axios;

  private authRepository: IAuthRepository;

  constructor(
    @inject(AUTH_TYPES.AuthRepository)
    authRepository: IAuthRepository,
    @inject(LEADS_TRACKING_TYPES.LeadsTrackingRepository)
    leadsTrackingRepository: ILeadsTrackingRepository,
  ) {
    this.client = Axios.create({
      baseURL: `${getApiUrl()}/`,
    });
    this.authRepository = authRepository;
    this.leadsTrackingRepository = leadsTrackingRepository;
    this.setInterceptors();
    this.setAuthHeader();
    this.setAdditionalHeaders();
    this.setLeadersTrackingHeader();
  }

  private setAdditionalHeaders(): void {
    this.client.defaults.headers.common['App-Type'] = 'dashboard';
    this.client.defaults.headers.common['App-Version'] = getAppVersion();
  }

  private setAuthHeader(): void {
    this.authRepository.getAccessToken().subscribe((token) => {
      if (token) {
        this.client.defaults.headers.common['Authorization'] = token;
      } else {
        delete this.client.defaults.headers.common['Authorization'];
      }
    });
  }

  private setLeadersTrackingHeader(): void {
    this.leadsTrackingRepository.get$().subscribe((leadsTracking) => {
      if (leadsTracking) {
        this.client.defaults.headers.common['x-utm'] = JSON.stringify(leadsTracking);
      } else {
        delete this.client.defaults.headers.common['x-utm'];
      }
    });
  }

  private setReferralTokenHeader(): void {
    // const token = localStorage.getItem(ReferrerTokenKey);
    // if (token) {
    //   this.client.defaults.headers.common['x-referrer-token'] = token;
    // }
  }

  private static waitOnline<T>(observableFactory: () => Observable<T>): Observable<T> {
    if (window.navigator.onLine) {
      return observableFactory();
    }

    return fromEvent(window, 'online').pipe(first(), switchMap(observableFactory));
  }

  get<T>(url: string, config?: AxiosRequestConfig): HttpClientResponseObservable<T> {
    return HttpClient.waitOnline(() => this.client.get<T>(url, config));
  }

  delete<T>(url: string, data): HttpClientResponseObservable<T> {
    return HttpClient.waitOnline(() => this.client.delete<T>(url, { data }));
  }

  patch<T>(url: string, data): HttpClientResponseObservable<T> {
    return HttpClient.waitOnline(() => this.client.patch<T>(url, data));
  }

  post<T>(url: string, data): HttpClientResponseObservable<T> {
    return HttpClient.waitOnline(() => this.client.post<T>(url, data));
  }

  put<T>(url: string, data): HttpClientResponseObservable<T> {
    return HttpClient.waitOnline(() => this.client.put<T>(url, data));
  }

  request<D, R>(config: AxiosRequestConfig<D>): HttpClientResponseObservable<R> {
    return HttpClient.waitOnline(() => this.client.request(config));
  }

  setInterceptors(): void {
    this.client.interceptors.request.use((config: AxiosRequestConfig) => {
      this.setReferralTokenHeader();

      return config;
    });

    this.client.interceptors.response.use(
      (response: AxiosResponse) => {
        return response;
      },
      (error: AxiosError) => {
        let networkError = new NetworkError(
          error.message,
          error.response?.status,
          error?.code,
          error?.response,
        );

        if (error.code === 'ERR_NETWORK') {
          networkError = new ConnectionError(error.message);
        }

        if (error.response?.status === 500) {
          networkError = new InternalServerError(error.message);
        }

        if (error.response?.status === 401) {
          networkError = new UnauthorizedError(error.message);
          this.globalErrorRepository.setExecutionError(networkError);
        }

        return Promise.reject(networkError);
      },
    );
  }
}
